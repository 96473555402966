import * as React from 'react'
import { ConnectWallet } from '../Wallet';

export default function Header() {
    return (
        <div className="flex flex-row justify-center bg-cyan-600 h-24 relative">
            <h1 className="font-FredokaOne text-6xl p-2 text-gray-50">Mask </h1>
            <svg className="h-20 w-20 flex-no-shrink fill-current m-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <g id="Fill_Outline" data-name="Fill Outline">
                    <g id="Color">
                        <g>
                            <path d="m57.289 28h-8.289v-15h2.816a5 5 0 0 1 4.531 2.886l3.661 7.845a3 3 0 0 1 -2.719 4.269z" fill="#f9e109" />
                            <path d="m60.193 25.675c-.183-.276-.365-.551-.562-.816a35.946 35.946 0 0 1 -5.665-10.334c-.133-.411-.289-.813-.446-1.213a4.977 4.977 0 0 0 -1.7-.312h-2.82v15h8.289a2.981 2.981 0 0 0 2.904-2.325z" fill="#fcbc04" />
                            <path d="m6.711 28h8.289v-15h-2.816a5 5 0 0 0 -4.531 2.886l-3.661 7.845a3 3 0 0 0 2.719 4.269z" fill="#e59730" />
                            <path d="m57.631 26.859a35.946 35.946 0 0 1 -5.665-10.334 20.985 20.985 0 0 0 -39.932 0 35.946 35.946 0 0 1 -5.665 10.334 16.976 16.976 0 0 0 7.243 25.889 15.978 15.978 0 0 0 13.388 7.252h10a15.978 15.978 0 0 0 13.388-7.252 16.976 16.976 0 0 0 7.243-25.889zm-40.631-2.859a5 5 0 1 1 9.929.707 7.97 7.97 0 0 0 -9.858 0 5 5 0 0 1 -.071-.707zm15 27a12.826 12.826 0 0 1 -12.794-12 23.279 23.279 0 0 0 25.588 0 12.826 12.826 0 0 1 -12.794 12zm14.929-26.293a7.97 7.97 0 0 0 -9.858 0 5 5 0 1 1 9.858 0z" fill="#f9e109" />
                            <path d="m60 39a28 28 0 0 1 -28-28v-9a20.988 20.988 0 0 0 -19.966 14.525 35.946 35.946 0 0 1 -5.665 10.334 16.976 16.976 0 0 0 7.243 25.889 15.978 15.978 0 0 0 13.388 7.252h10a15.978 15.978 0 0 0 13.388-7.252 17 17 0 0 0 10.483-13.748zm-43-15a5 5 0 1 1 9.929.707 7.97 7.97 0 0 0 -9.858 0 5 5 0 0 1 -.071-.707zm15 27a12.826 12.826 0 0 1 -12.794-12 23.279 23.279 0 0 0 25.588 0 12.826 12.826 0 0 1 -12.794 12z" fill="#fcbc04" />
                            <circle cx="53" cy="36" fill="#f89c8d" r="4" />
                            <circle cx="11" cy="36" fill="#f8664f" r="4" />
                            <path d="m49.08 36.79a3.985 3.985 0 0 0 6.852 1.91 27.773 27.773 0 0 1 -6.852-1.91z" fill="#f8664f" />
                            <path d="m37 54c0 1.1-2.239 0-5 0s-5 1.1-5 0 2.239-2 5-2 5 .9 5 2z" fill="#e59730" />
                            <g>
                                <path d="m35 34v-6a3 3 0 0 0 -3-3 3 3 0 0 0 -3 3v6a2 2 0 1 0 .675 3.875 2.965 2.965 0 0 0 4.65 0 2 2 0 1 0 .675-3.875z" fill="#f89c8d" />
                                <path d="m32 32v-7a3 3 0 0 0 -3 3v6a2 2 0 1 0 .675 3.875 2.965 2.965 0 0 0 4.65 0 1.982 1.982 0 0 0 2.675-1.875h-1a4 4 0 0 1 -4-4z" fill="#f8664f" />
                            </g>
                            <g fill="#e59730">
                                <path d="m37.823 31.312c.746-.815 1.65 1.512 3.687 3.377s4.433 2.563 3.687 3.377-3-.037-5.038-1.9-3.082-4.04-2.336-4.854z" />
                                <path d="m26.849 31.312c-.746-.815-1.65 1.512-3.687 3.377s-4.433 2.563-3.687 3.377 3-.037 5.038-1.9 3.087-4.04 2.336-4.854z" />
                            </g>
                        </g>
                    </g>
                    <g id="Outline_copy" data-name="Outline copy">
                        <g fill="#1f2d51">
                            <path d="m59.674 28.186a4.037 4.037 0 0 0 1.315-1.666 3.929 3.929 0 0 0 -.073-3.213l-3.66-7.839a6.017 6.017 0 0 0 -5.436-3.468h-.788a21.946 21.946 0 0 0 -32.476-6.392 1 1 0 1 0 1.224 1.58 19.965 19.965 0 0 1 31.235 9.645 28.415 28.415 0 0 0 1.479 3.642 1 1 0 0 0 .9.565 1 1 0 0 0 .9-1.435 26.559 26.559 0 0 1 -1.378-3.387 22.263 22.263 0 0 0 -.859-2.2 4.008 4.008 0 0 1 3.385 2.3l3.658 7.837a1.953 1.953 0 0 1 .039 1.6 2.055 2.055 0 0 1 -.544.75c-.058-.08-.108-.164-.167-.243-.812-1.085-1.568-2.181-2.246-3.258a1 1 0 1 0 -1.692 1.067c.706 1.121 1.492 2.262 2.336 3.389a15.976 15.976 0 0 1 -6.818 24.363 1 1 0 0 0 -.46.379 15.284 15.284 0 0 1 -1.365 1.768 1 1 0 0 0 1.5 1.33 17.843 17.843 0 0 0 1.369-1.742 17.955 17.955 0 0 0 8.623-25.37z" />
                            <path d="m45.322 56.467a14.9 14.9 0 0 1 -8.322 2.533h-10a14.964 14.964 0 0 1 -12.551-6.8 1 1 0 0 0 -.461-.379 15.976 15.976 0 0 1 -6.818-24.363c.787-1.053 1.53-2.126 2.208-3.188a1 1 0 1 0 -1.686-1.077c-.651 1.022-1.366 2.054-2.124 3.067-.059.079-.109.163-.167.243a2.055 2.055 0 0 1 -.544-.75 1.958 1.958 0 0 1 .039-1.6l3.661-7.841a4.007 4.007 0 0 1 3.378-2.294 21.638 21.638 0 0 0 -.852 2.2 26.689 26.689 0 0 1 -1.476 3.591 1 1 0 0 0 1.8.884 28.618 28.618 0 0 0 1.583-3.859 19.851 19.851 0 0 1 3.964-6.977 1 1 0 0 0 -1.508-1.314 22.211 22.211 0 0 0 -2.474 3.457h-.792a6.018 6.018 0 0 0 -5.436 3.467l-3.659 7.838a3.93 3.93 0 0 0 -.074 3.215 4.037 4.037 0 0 0 1.315 1.666 17.954 17.954 0 0 0 8.623 25.369 16.954 16.954 0 0 0 14.051 7.445h10a16.891 16.891 0 0 0 9.436-2.872 1 1 0 1 0 -1.114-1.661z" />
                            <path d="m19.754 38.164a1 1 0 0 0 -1.547.9 13.821 13.821 0 0 0 27.586 0 1 1 0 0 0 -1.547-.9 22.281 22.281 0 0 1 -24.492 0zm12.246 11.836a11.859 11.859 0 0 1 -11.494-9.062 24.28 24.28 0 0 0 22.988 0 11.859 11.859 0 0 1 -11.494 9.062z" />
                            <path d="m29 35a1 1 0 0 0 1-1v-5a1 1 0 0 0 -2 0v4.171a3 3 0 0 0 1.348 5.807 3.952 3.952 0 0 0 5.3 0 3 3 0 0 0 1.352-5.807v-4.171a1 1 0 0 0 -2 0v5a1 1 0 0 0 1 1 1 1 0 0 1 0 2 .984.984 0 0 1 -.338-.066 1 1 0 0 0 -1.116.313 2.023 2.023 0 0 1 -3.092 0 1 1 0 0 0 -1.116-.313.984.984 0 0 1 -.338.066 1 1 0 0 1 0-2z" />
                            <path d="m22 18a6.006 6.006 0 0 0 -6 6 5.813 5.813 0 0 0 .081.848 1 1 0 0 0 1.607.646 7.074 7.074 0 0 1 8.624 0 1 1 0 0 0 .994.1 1.065 1.065 0 0 0 .624-.824 5.312 5.312 0 0 0 .07-.77 6.006 6.006 0 0 0 -6-6zm0 4a8.9 8.9 0 0 0 -3.834.858 4 4 0 0 1 7.668 0 8.9 8.9 0 0 0 -3.834-.858z" />
                            <path d="m42 18a6.006 6.006 0 0 0 -6 6 5.813 5.813 0 0 0 .081.848 1 1 0 0 0 1.607.646 7.074 7.074 0 0 1 8.624 0 1 1 0 0 0 .994.1 1.065 1.065 0 0 0 .624-.824 5.312 5.312 0 0 0 .07-.77 6.006 6.006 0 0 0 -6-6zm0 4a8.9 8.9 0 0 0 -3.834.858 4 4 0 0 1 7.668 0 8.9 8.9 0 0 0 -3.834-.858z" />
                            <path d="m53 39a3 3 0 0 1 -3-3 1 1 0 0 0 -2 0 5 5 0 1 0 5-5 1 1 0 0 0 0 2 3 3 0 0 1 0 6z" />
                            <path d="m11 33a1 1 0 0 0 0-2 5 5 0 1 0 5 5 1 1 0 0 0 -2 0 3 3 0 1 1 -3-3z" />
                            <path d="m28.445 55.168a1 1 0 0 0 1.11 1.664 4.4 4.4 0 0 1 4.89 0 1 1 0 1 0 1.11-1.664 6.4 6.4 0 0 0 -7.11 0z" />
                            <path d="m44.684 16.949a1 1 0 0 0 .632-1.9l-6-2a1 1 0 0 0 -.632 1.9z" />
                            <path d="m22.684 13.051-6 2a1 1 0 0 0 .632 1.9l6-2a1 1 0 0 0 -.632-1.9z" />
                            <path d="m43 37a1 1 0 0 0 .2-1.98 2.82 2.82 0 0 1 -2.22-2.22 1 1 0 1 0 -1.96.392 4.807 4.807 0 0 0 3.78 3.788.948.948 0 0 0 .2.02z" />
                            <path d="m21.2 36.98a4.807 4.807 0 0 0 3.78-3.78 1 1 0 1 0 -1.96-.392 2.82 2.82 0 0 1 -2.22 2.212 1 1 0 0 0 .2 1.98.955.955 0 0 0 .2-.02z" />
                        </g>
                    </g>
                </g>
            </svg>
            <h1 className="font-FredokaOne text-6xl p-2 text-gray-50">Mint</h1>
            <div className="absolute inset-y-6 right-4">
                <ConnectWallet></ConnectWallet>
            </div>
        </div>
    )
}